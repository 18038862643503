@import"../variables";
.footer-header {
    background-color: $main-bg;

}

.footer-area {
   background-image:url('/images/bg-foot.jpg');
    .foot-logo {
        width: 200px;
        .img-fluid.img-foot-logo {

        }
    }
    .title h4{
        color: white;
    }
   .text p {
        font-size: 15px;
        color: $white;
    }

    ul.content {
        li {
            padding: 5px 0;
            color: $white;
            a {
                color: $white;
            }
        }
    }
    .con-social {
        ul {
            li {
                margin-left: 10px;
                a {
                    color: white;
                    i {
                        font-size: 21px;
                        color: white;
                    }
                    span {
                        font-size: 16px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .footer-bottom {
        border-top: 1px solid #fff;
        span {
            color: #B7B7B7;
        }
    }
}

@media only screen and(max-width:576px) {
    .footer-botton {
        .pr-team {
            display: none !important;
        }
    }
}

@media only screen and(max-width:768px) {
    .footer-botton {
        .pr-team {
            text-align: unset !important;
        }
    }
    .q-text {
        .row {
            width: 100% !important;
        }
    }
}

// @media only screen and (max-width:576px) {
//     .footer-area {
//         .row {
//             text-align: center !important;
//             .con-social {
//                 .nav {
//                     width: 110px !important;
//                     margin: auto !important;
//                 }
//             }
//         }
//     }
// }
// @media only screen and (max-width:768px) {
//     .footer-area {
//         .foot-logo {
//             padding: 0px;
//             img {
//                 height: 60px !important;
//             }
//         }
//         h4.foot-title {
//             font-size: 20px !important;
//         }
//     }
// }
