@import"../variables";
@import"responsive-header";
.dropdown:hover>.dropdown-menu {
    display: block;
}

// .dropdown>.dropdown-toggle:active {
//     /*Without this, clicking will make it sticky*/
//     // pointer-events: none;
// }
.navbar-area {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    top: 0;
    box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
    z-index: 999;
    background-color: #ffffff;
    .col-3 {
        flex: 1;
        max-width: 220px;
        &.con-logo {
            .img-fluid.img-logo {
                max-width: 100%;
                height: 80px;
                padding: 1rem 0;
            }
        }
    }
    .col-9.center-menu {
        flex: 1;
        max-width: calc(100% - 220px);
        ul.nav {
            align-items: center;
            height: 80px;
            li{
                &.nav-item {
                    position: relative;
                    padding: 5px 10px;
                    &.active {
                        a.nav-link {
                            color: $ha-color;
                        }
                    }
                    a.main-link {
                        padding: 0;
                        font-weight: 600;
                        font-size: 14px;
                        color: $text;
                        text-transform: uppercase;
                        &:hover {
                            color: $ha-color;
                        }
                        &.dr-link {
                            &~.dropdown-menu {
                                transform: unset !important;
                                top: 30px !important;
                                left: -27px !important;
                                min-width: 140px;
                                border: unset;
                                padding: 15px 10px;
                                border-radius: unset;
                                li {
                                    &.active {
                                        background-color: #e9ecef;
                                    }
                                }
                                li a {
                                    font-weight: 100;
                                    padding: 5px 15px;
                                    span {
                                        padding-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.btn-request{
                    a.main-link{
                     color: #ffffff;
                        padding: 5px 10px;
                        border-radius: 30px;
                        background-color: $ha-color;
                        transition:.3s;
                        &:hover{
                            background-color: $main;
                            color: #ffffff;
                        }
                    }
                    &.active a.main-link{

                        color: #ffffff;
                    }
                }
            }
            &.phone{
                display: none;
            }
        }


    }
    .col-3.right-btn {
        flex: 1;
        max-width: 200px;
        height: 80px;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        .language {
            margin-right: 18px;
            a {
                &.active {
                    img {
                        border: 1px solid #fa695f;
                        border-radius: 2px;
                    }
                }
            }
        }
        .icon {
            .sub-icon {
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    .about {
        width: 90px;
    }
    .shop {
        width: 50px;
    }
    .main-link:hover {
        #down {
            display: none !important;
        }
        #up-about {
            display: block !important;
            position: absolute;
            top: 8px;
            left: 84px;
        }
        #up-shop {
            display: block !important;
            position: absolute;
            top: 8px;
            left: 53px;
        }
    }
}

@media only screen and(max-width:1024px) {

}

// nav app
.nav-app {
    display: none !important;
    margin-bottom: 40px;
}

#nav-top {
    position: relative;
    width: 100%;
    top: 0;

    box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
    z-index: 90;
    .nav-mobile {
        .row {
            padding: 8px;
            .col-12 {
                .logo {

                }
                #menu-button {
                    padding: 5px 0;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    align-content: center;
                    height: 100%;
                    margin-left: 10px;
                }
                i {
                    font-size: 30px !important;
                    color: white
                }
            }
        }
    }
}

#menu {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    background: #f4f6f8;
    width: 80%;
    max-width: 20rem;
    height: 100%;
    transition: transform .3s ease-out;
    .left-navbar {
        background-color: #fafafa;
        box-shadow: 0px 0px 2px 0px;
        display: flex;
        padding: 6px;
        .logo {
            img {
                max-width: 40%;
            }
        }
        // #i-close {
        //     margin-top: 8px;
        //     font-size: 25px;
        //     font-weight: unset;
        // }
    }
    .container-fluid {
        overflow-y: auto;
        max-width: 100%;
        .list-menu {
            list-style-type: none;
            margin: 0 13px;
            padding: 0;
            li {
                border-bottom: 2px solid #ffffff;
                position: relative;
                &.active {
                    a {
                        color: $ha-color;
                    }
                }
                a:hover {
                    color: $ha-color;
                }
            }
        }
    }
}

.background {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 516;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    background-color: rgb(37 88 145 / 40%);
    transition: transform .3s ease-out;
}

@media only screen and (max-width: 992px) {
    .new-bk {
        visibility: visible !important;
        transform: translateX(0) !important;
    }
    .open {
        visibility: visible !important;
        transform: translateX(0) !important;
    }
}

@media only screen and (max-width: 992px) {
    .col-9.center-menu{
        ul.nav.justify-content-end{
            display: none;
            &.phone{
                display: flex;
                .icon{
                    font-size: 26px;
                    color: $main;
                }
            }
        }
    }
    .navbar-area {

    }
}

@media only screen and (max-width: 992px) {
    .nav-app {

    }
}

@media only screen and (max-width: 768px) {
    .nav-mobile {
        .col-4 {

        }
    }
}
